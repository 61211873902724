import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import SEO from "../components/seo"

const Terms = () => {

    const i18n = useIntl().formatMessage

    return (
    <>
        <SEO title={ i18n({ id: "pages.terms.title" })} />
        <section className="section-padding">
            <div className="container">
                <div className="row center-xs">
                <div className="col col-xs-12 col-md-6">
                    <h1 className="text-center">{ i18n({ id: "pages.terms.title" })}</h1>
                    <p>{ i18n({ id: "pages.terms.text" })}</p>
                    <h2>{ i18n({ id: "pages.terms.subtitle_one" })}</h2>
                    <p>{ i18n({ id: "pages.terms.text_one" })}</p>
                    <h2>{ i18n({ id: "pages.terms.subtitle_two" })}</h2>
                    <p>{ i18n({ id: "pages.terms.text_two" })}</p>
                    <h2>{ i18n({ id: "pages.terms.subtitle_three" })}</h2>
                    <p>{ i18n({ id: "pages.terms.text_three" })}</p>
                    <h2>{ i18n({ id: "pages.terms.subtitle_four" })}</h2>
                    <p>{ i18n({ id: "pages.terms.text_four" })}</p>
                    <h2>{ i18n({ id: "pages.terms.subtitle_five" })}</h2>
                    <p>{ i18n({ id: "pages.terms.text_five" })}</p>
                    <h2>{ i18n({ id: "pages.terms.subtitle_six" })}</h2>
                    <p>{ i18n({ id: "pages.terms.text_six" })}</p>
                    <h2>{ i18n({ id: "pages.terms.subtitle_seven" })}</h2>
                    <p>{ i18n({ id: "pages.terms.text_seven" })}</p>
                    <h2>{ i18n({ id: "pages.terms.subtitle_eight" })}</h2>
                    <p>{ i18n({ id: "pages.terms.text_eight" })}</p>
                    <h2>{ i18n({ id: "pages.terms.subtitle_nine" })}</h2>
                    <p>{ i18n({ id: "pages.terms.text_nine" })}</p>
                    <h2>{ i18n({ id: "pages.terms.subtitle_ten" })}</h2>
                    <p>{ i18n({ id: "pages.terms.text_ten" })}</p>
                </div>
                </div>
            </div>
        </section>
    </>
    )
    }

export default Terms
